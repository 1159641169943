<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Browse' }">
            Browse
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Examination', query: { examId } }">
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ sectionName }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1>
        {{ pageTitle }}
        <router-link :to="{ name: 'CreatePassage' }">
          <i class="el-icon-plus" />
        </router-link>
      </h1>
      <el-table class="passages" width="100%" :data="passages">
        <el-table-column label="Content" width="800px">
          <template slot-scope="scope">
            <div v-html="scope.row.content.slice(0, 100)" />
            <router-link
              :to="{
                name: 'Passage',
                query: { passageId: scope.row.id, examId }
              }"
            >
              {{ `${scope.row.content.length > 100 ? "More..." : ""}` }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="Question Count">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'Passage',
                query: { passageId: scope.row.id, examId }
              }"
            >
              {{ scope.row.question_count }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Action" width="100">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'Passage',
                query: { passageId: scope.row.id, examId }
              }"
            >
              <i class="fa fa-eye action-icon" />
            </router-link>
            <router-link
              :to="{
                name: 'EditPassage',
                query: { passageId: scope.row.id, examId }
              }"
            >
              <i class="fa fa-edit" />
            </router-link>
            <span @click="() => deletePassage(scope.row.id)">
              <i class="fas fa-trash-alt warning-icon" />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import satApi from "@/apis/sat.js";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  props: [],
  data() {
    return {
      sectionName: "",
      testName: "",
      passages: []
    };
  },
  computed: {
    pageTitle() {
      return "Passages";
    },
    examId() {
      return this.$route.query.examId;
    }
  },
  watch: {},

  async created() {
    await this.fecthPassages();
    const { full_name } = await satApi.getTest(this.examId);
    this.testName = full_name;
    this.sectionName = "Reading";
  },
  methods: {
    async fecthPassages() {
      this.passages = await satApi.getSubjectPassages(
        this.$route.params.sectionId
      );
    },
    async deletePassage(passageId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await satApi.deletePassage(this.$route.params.testType, passageId);
        this.$message.success(this.$t("message.delete_success"));
        this.fecthPassages();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
.passages {
  padding-bottom: 40px;
}
</style>
